<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><router-link :to="{name:'home'}">Home</router-link></li>
          <li><a href="javascript:void(0);">Help & Support</a></li>
        </ul>
      </div>
    </div>
    <section class="section--faq ps-page--business">
      <div class="container">
        <h2 class="page__title pb-4">Help & Support</h2>



        <div class="faq__content">
          <h4>
            Below are frequently asked questions, you may find the answer for
            yourself
          </h4>
          <p>
Check out this section to get answers for all the frequently asked questions.
          </p>
        </div>
        <div id="accordion" class="card__accordion">
          <div class="card card_dipult mb-3">
            <div id="headingOne" class="card-header card_accor bg-gray">
              <button
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                class="btn btn-link text-black w-100"
                v-on:click="isActive = !isActive"
              >
                <div class="text-black float-left font-14">
                  How do I register?
                </div>

                <i
                  :class="[
                    !isActive
                      ? ' fa fa-minus float-right text-black'
                      : 'fa fa-plus float-right text-black',
                  ]"
                ></i>
              </button>
            </div>
            <div
              id="collapseOne"
              aria-labelledby="headingOne"
              data-parent="#accordion"
              style=""
            >
              <div v-if="!isActive" class="card-body">
                <p>
                 You can register by clicking on the "Sign In" section located at the top right corner on the home page. Please provide the required information in the form that appears and click on submit. you can start shopping on Suyeemarket.
                </p>
              </div>
            </div>
          </div>
          <div class="card card_dipult mb-3">
            <div id="headingTwo" class="card-header card_accor bg-gray">
              <button
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
                class="btn btn-link text-black w-100"
                v-on:click="isAct1 = !isAct1"
              >
                <div class="text-black float-left font-14">
                 Do I need to register before shopping on Suyeemarket?
                </div>

                <i
                  :class="[
                    !isAct1
                      ? ' fa fa-minus float-right text-black'
                      : 'fa fa-plus float-right text-black',
                  ]"
                ></i>
              </button>
            </div>
            <div
              id="collapseTwo"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
              style=""
            >
              <div v-if="!isAct1" class="card-body">
                <p>
                  Yes, you do need to register before shopping with us. However, you can browse the website without registration. You are required to login or register while you checkout.
                </p>
              </div>
            </div>
          </div>
          <div class="card card_dipult mb-3">
            <div id="headingThree" class="card-header card_accor bg-gray">
              <button
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
                class="btn btn-link text-black w-100"
                v-on:click="isAct2 = !isAct2"
              >
                <div class="text-black float-left font-14">
                  Can I register multiple times using the same phone number/email ID?
                </div>

                <i
                  :class="[
                    !isAct2
                      ? ' fa fa-minus float-right text-black'
                      : 'fa fa-plus float-right text-black',
                  ]"
                ></i>
              </button>
            </div>
            <div
              id="collapseThree"
              aria-labelledby="headingThree"
              data-parent="#accordion"
              style=""
            >
              <div v-if="!isAct2" class="card-body">
                <p>
                 Each email ID/login ID and mobile number can only be associated with one customer account.
                </p>
              </div>
            </div>
          </div>

           <div class="card card_dipult mb-3">
            <div id="headingFour" class="card-header card_accor bg-gray">
              <button
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
                class="btn btn-link text-black w-100"
                v-on:click="isAct3 = !isAct3"
              >
                <div class="text-black float-left font-14">
                  What if I enter the wrong email ID while registering online/through the phone?
                </div>

                <i
                  :class="[
                    !isAct3
                      ? ' fa fa-minus float-right text-black'
                      : 'fa fa-plus float-right text-black',
                  ]"
                ></i>
              </button>
            </div>
            <div
              id="collapseFour"
              aria-labelledby="headingFour"
              data-parent="#accordion"
              style=""
            >
              <div v-if="!isAct3" class="card-body">
                <p>
                  Please contact our customer support team at +1 (973) 341 0582 ,+94 (768) 514 527 or write to us at: suyeemarket@gmail.com  and we will fix this issue for you.
                </p>
              </div>
            </div>
          </div>


          <h4 style="margin-top:4rem"> Ordering</h4>

          <div class="card card_dipult mb-3">
            <div id="headingFive" class="card-header card_accor bg-gray">
              <button
                data-toggle="collapse"
                data-target="#collapseFive"
                aria-expanded="true"
                aria-controls="collapseFive"
                class="btn btn-link text-black w-100"
                v-on:click="isAct4 = !isAct4"
              >
                <div class="text-black float-left font-14">
                  How do I know if I placed my order correctly?
                </div>

                <i
                  :class="[
                    !isAct4
                      ? ' fa fa-minus float-right text-black'
                      : 'fa fa-plus float-right text-black',
                  ]"
                ></i>
              </button>
            </div>
            <div
              id="collapseFive"
              aria-labelledby="headingFive"
              data-parent="#accordion"
              style=""
            >
              <div v-if="!isAct4" class="card-body">
                <p>
                 Upon the successful completion of your order, an order confirmation e-mail and SMS containing your order details will be sent to your registered email ID and phone number.
                </p>
              </div>
            </div>
          </div>

          <div class="card card_dipult mb-3">
            <div id="headingFive" class="card-header card_accor bg-gray">
              <button
                data-toggle="collapse"
                data-target="#collapseFive"
                aria-expanded="true"
                aria-controls="collapseFive"
                class="btn btn-link text-black w-100"
                v-on:click="isAct5 = !isAct5"
              >
                <div class="text-black float-left font-14">
                  Can I call and place an order?
                </div>

                <i
                  :class="[
                    !isAct5
                      ? ' fa fa-minus float-right text-black'
                      : 'fa fa-plus float-right text-black',
                  ]"
                ></i>
              </button>
            </div>
            <div
              id="collapseFive"
              aria-labelledby="headingFive"
              data-parent="#accordion"
              style=""
            >
              <div v-if="!isAct5" class="card-body">
                <p>
                 No, currently this service is not available.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  data() {
    return {
      isActive: true,
      isAct1: true,
      isAct2: true,
      isAct3: true,
      isAct4: true,
      isAct5: true,
      isAct6: true,

    };
  },
};
</script>